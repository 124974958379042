export interface Country {
  name: string;
  code: string;
  [key: string]: any;
}

export class CountriesMap {
  private namesMap = new Map<string, Country>();
  private codesMap = new Map<string, Country>();

  add(c: Country) {
    this.namesMap.set(c.name, c);
    this.codesMap.set(c.code, c);
  }

  byCode(code: string) {
    return this.codesMap.get(code);
  }

  byName(name: string) {
    return this.namesMap.get(name);
  }

  codesToNames(codes: string[]): string[] {
    return codes.map((c) => {
      const country = this.codesMap.get(c);
      return country ? country.name : undefined
    }).filter((n) => n) as string[];
  }

  namesToCodes(names: string[]) {
    return names.map((n) => {
      const country = this.namesMap.get(n);
      return country ? country.code : undefined
    }).filter((c) => c);
  }
}

export const countriesRegistry = new CountriesMap();
