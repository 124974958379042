import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ServiceProject} from "../../domain/Service";
import {projectApi} from "../api/API";
import {cloneDeep} from "lodash";

let itemsPerPage = 9;
let currentPage = 1;
let filter = '';

interface ItemsState<T> {
  items: T[];
  itemsTotal: number;
  currentPage: number;
  itemsPerPage: number;
  filter: string;
}

export interface ServiceState {
  isLoading?: boolean;
  services: ServiceProject[];
  itemsTotal: number;
  currentPage: number;
  itemsPerPage: number;
  filter: string;
}

const initialState: ServiceState = {
  services: [],
  itemsTotal: 0,
  currentPage: 1,
  itemsPerPage: 9,
  filter: ''
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    setServices: (state, action: PayloadAction<ItemsState<ServiceProject>>) => {
      state.services = action.payload.items;
      state.itemsTotal = action.payload.itemsTotal;
      state.itemsPerPage = action.payload.itemsPerPage;
      state.currentPage = action.payload.currentPage;
      state.filter = action.payload.filter;
      state.isLoading = false;
    },
  }
});

const {setLoading, setServices} = serviceSlice.actions;

export const getServices = createAsyncThunk(
  'get-services',
  async (arg: {currentPage: number, itemsPerPage: number, filter: string}, {dispatch}) => {
    dispatch(setLoading());
    currentPage = arg.currentPage;
    itemsPerPage = arg.itemsPerPage;
    filter = arg.filter;
    let offset = (currentPage - 1) * itemsPerPage;
    if (offset < 0) {
      offset = 0;
    }

    const services = await projectApi.list({range: {limit: itemsPerPage, offset}, commonFilter: filter});

    dispatch(setServices({ ...services, currentPage, itemsPerPage, filter}));
  }
);

export const checkName = async (name: string, project?: ServiceProject) => {
  if (project && project.identity === name) {
    return true;
  }
  const resp: any = await projectApi.checkName(name.trim());
  return resp.ok;
};

export const saveService = createAsyncThunk(
  'save-service',
  async (arg: {service: ServiceProject, changes: any}, {dispatch}) => {
    const {service, changes} = arg;
    dispatch(setLoading());
    const id = service.identity;
    const updated: any = cloneDeep(service || {});
    Object.keys(changes).forEach((k) => updated[k] = changes[k]);
    delete updated.logoFile;
    delete updated.bannerFile;
    let newItem = updated;
    if (!id) {
      newItem = await projectApi.create(updated);
    } else {
      await projectApi.save(updated, {prevIdentity: id});
    }
    if (changes.logoFile) {
      try {
        await projectApi.updateLogo(newItem, changes.logoFile);
      } catch (e) {
        console.log('Error with logo', e);
      }
    }

    if (changes.bannerFile) {
      try {
        await projectApi.updateBanner(newItem, changes.bannerFile);
      } catch (e) {
        console.log('Error with banner', e);
      }
    }
    await projectApi.sendToReview(newItem);
    await dispatch(getServices({currentPage, itemsPerPage, filter}));
  }
);

export const disableService = createAsyncThunk(
  'disable-service',
  async (service: ServiceProject, {dispatch}) => {
    dispatch(setLoading());
    await projectApi.disable(service);
    await dispatch(getServices({currentPage, itemsPerPage, filter}));
  }
);

export const enableService = createAsyncThunk(
  'enable-service',
  async (service: ServiceProject, {dispatch}) => {
    dispatch(setLoading());
    await projectApi.enable(service);
    await dispatch(getServices({currentPage, itemsPerPage, filter}));
  }
);

export const deleteService = createAsyncThunk(
  'delete-service',
  async (service: ServiceProject, {dispatch}) => {
    dispatch(setLoading());
    await projectApi.delete(service);
    await dispatch(getServices({currentPage, itemsPerPage, filter}));
  }
);

export const changeServiceVisibility = createAsyncThunk(
  'change-service-visibility',
  async (item: ServiceProject, {dispatch}) => {
    dispatch(setLoading());
    const updated: any = cloneDeep(item);
    updated.isPublic = !updated.isPublic;
    delete updated.logoFile;
    delete updated.bannerFile;
    await projectApi.save(updated, {prevIdentity: item.identity});
    await projectApi.sendToReview(item);
    await dispatch(getServices({currentPage, itemsPerPage, filter}));
  }
);
