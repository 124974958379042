import {httpDelete, httpGetJSON, httpPostEmpty, httpPostJSON, httpPutFile, httpPutJSON, signUpToken} from "./Fetch";
import {appConfig, customerPrefix} from "../cfg/config";
import {conditionToQueryString} from "./QueryString";
import {PaymentPlan} from "../../domain/Service";
import {Converter} from "./Converter";
import {ChangeOptions, GetCondition, ListItemApi} from "./ListItemApi";

interface PaymentPlanApiObject {
  paymentPlanId: string;
  name: string;
  price: number;
  onPremPrice: number;
  description: string;
  logo?: string;
}

class PaymentPlanConverter extends Converter<PaymentPlan, PaymentPlanApiObject> {
  apiObjectToItem(obj: PaymentPlanApiObject, parent?: undefined): PaymentPlan {
    const item: PaymentPlan = {...obj, identity: obj.paymentPlanId};
    if (obj.logo) {
      item.logo = `${appConfig().ZAKA_BACKEND}${obj.logo}`;
    }
    return item;
  }

  itemToApiObject(item: PaymentPlan): PaymentPlanApiObject {
    const res: PaymentPlanApiObject = {...item, paymentPlanId: item.identity};
    return res;
  }
}

const FAKE_PLANS: PaymentPlanApiObject[] = [
  {paymentPlanId: 'month', name: 'Monthly', description: 'Pay every month', price: 43, onPremPrice: 32},
  {paymentPlanId: 'year', name: 'Yearly', description: 'Pay once per year', price: 37, onPremPrice: 29},
];

const converterObject = {
  identity: 'id',
};

export class PaymentPlanApi extends ListItemApi<PaymentPlan, PaymentPlanApiObject> {
  constructor() {
    super(new PaymentPlanConverter());
  }

  protected async fetchOne(condition?: GetCondition): Promise<PaymentPlanApiObject> {
    // return FAKE_PLANS[0];
    const name = condition && condition.identity ? condition.identity : '';
    return await httpGetJSON(`${appConfig().ZAKA_BACKEND}/catalogue/payment-plans/${name}`, signUpToken());
  }

  protected async fetchList(condition?: GetCondition) {
    // return {itemsTotal: FAKE_PLANS.length, items: FAKE_PLANS};
    const resp = await httpGetJSON(
      `${appConfig().ZAKA_BACKEND}/catalogue/payment-plans/list${conditionToQueryString(condition, converterObject)}`,
      signUpToken()
    );
    return {items: resp, itemsTotal: resp.length};
  }

  protected async fetchCreate(item: PaymentPlanApiObject): Promise<PaymentPlanApiObject> {
    return item;
  }

  protected async fetchSave(item: PaymentPlanApiObject, opt?: ChangeOptions): Promise<void> {
    // do nothing
  }

  protected async fetchDelete(item: PaymentPlanApiObject): Promise<void> {
    // do nothing
  }

}
