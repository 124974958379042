import {httpGetJSON} from "./Fetch";
import {appConfig} from "../cfg/config";
import {Container} from "../../domain/Container";
import {wait} from "../../utils/TimeUtils";

export class ContainersApi {
  async ping(url: string) {
    /*await wait(500);
    return true;*/

    let ok = false;
    try {
      const resp = await httpGetJSON(`${url}/admin-accept/ping`);
      ok = resp.ok;
    } catch (e) {
      console.log(e);
    }
    return ok;
  }

  async publicList(): Promise<Container[]> {
    return await httpGetJSON(`${appConfig().ZAKA_BACKEND}/service-containers/public-list`);
  }

}
