import {httpGetJSON} from "./Fetch";
import {Country} from "../../domain/catalogue/Country";
import {appConfig} from "../cfg/config";

export class CountriesApi {
  private cache = new Map<string, Country>();

  async list() {
    const res = await httpGetJSON(`${appConfig().ZAKA_BACKEND}/catalogue/countries/list`);
    this.cache = new Map<string, Country>();
    res.forEach((obj: any) => {
      const country = obj;
      this.cache.set(country.code, country);
    });
    return this.listFromCache();
  }

  listFromCache() {
    return [...this.cache.values()];
  }

}
