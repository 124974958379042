import { configureStore } from '@reduxjs/toolkit'
import {authSlice} from "./state/auth";
import {serviceSlice} from "./state/services";
import {catalogueSlice} from "./state/catalogue";
import {appSlice} from "./state/app";
import {statisticsSlice} from "./state/statistics";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    auth: authSlice.reducer,
    service: serviceSlice.reducer,
    catalogue: catalogueSlice.reducer,
    statistics: statisticsSlice.reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
