import {httpDelete, httpGetJSON, httpPostJSON, httpPutFile} from "./Fetch";
import {Category} from "../../domain/catalogue/Category";
import {appConfig} from "../cfg/config";

export class CategoriesApi {
  private cache = new Map<string, Category>();

  async list() {
    const res = await httpGetJSON(`${appConfig().ZAKA_BACKEND}/catalogue/categories/list`);
    this.cache = new Map<string, Category>();
    res.forEach((obj: any) => {
      const category = obj;
      category.logo = `${appConfig().ZAKA_BACKEND}${category.logo}`;
      this.cache.set(category.id, category);
    });
    return this.listFromCache();
  }

  listFromCache() {
    return [...this.cache.values()];
  }

}
