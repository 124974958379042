import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {countriesRegistry, Country} from "../../domain/catalogue/Country";
import {Category} from "../../domain/catalogue/Category";
import {categoriesApi, containersApi, countriesApi, paymentPlansApi} from "../api/API";
import {PaymentPlan} from "../../domain/Service";
import {containersCatalogue} from "../../domain/Container";

export interface CatalogueState {
  countries: Country[];
  categories: Category[];
  paymentPlans: PaymentPlan[];
}

const initialState: CatalogueState = {
  countries: [],
  categories: [],
  paymentPlans: []
};

export const catalogueSlice = createSlice({
  name: 'catalogue',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setPaymentPlans: (state, action: PayloadAction<PaymentPlan[]>) => {
      state.paymentPlans = action.payload;
    },
  }
});

const {setCountries, setCategories, setPaymentPlans} = catalogueSlice.actions;

export const initCatalogue = createAsyncThunk('init-catalogue', async (_, {dispatch}) => {
  const containers = await containersApi.publicList();
  containersCatalogue.setContainers(containers);
  const countries = await countriesApi.list();
  countries.forEach((c) => countriesRegistry.add(c));
  dispatch(setCountries(countries));
  const categories = await categoriesApi.list();
  dispatch(setCategories(categories));
  const ppResp = await paymentPlansApi.list();
  dispatch(setPaymentPlans(ppResp.items));
});
