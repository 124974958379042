import {httpDelete, httpGetJSON, httpPostJSON, httpPutJSON, signUpToken, token} from "./Fetch";
import {appConfig, customerPrefix} from "../cfg/config";
import {conditionToQueryString} from "./QueryString";
import {Converter} from "./Converter";
import {Service} from "../../domain/Service";
import {GetCondition, ListItemApi} from "./ListItemApi";
import {StatisticsMonthly} from "../../domain/Statistics";

interface ServiceApiObject {
  name: string;
  owner: string;
  description: string;
  shortDescription: string;
  publicDid: string;
  enabled: boolean;
  logo: string;
  banner: string;
  categories: string[];
  countries: string[];
  keywords: string[];
  endpointsBase: string;
  lastTouch: string;
  isPublic: boolean;
  container: string;
}

class ServiceConverter extends Converter<Service, ServiceApiObject> {
  apiObjectToItem(obj: ServiceApiObject, parent?: undefined): Service {
    const item: Service = {...obj, identity: obj.name, did: obj.publicDid, endpoint: obj.endpointsBase};
    if (obj.logo) {
      item.logo = `${appConfig().ZAKA_BACKEND}${obj.logo}`;
    }
    if (obj.banner) {
      item.banner = `${appConfig().ZAKA_BACKEND}${obj.banner}`;
    }
    return item;
  }

  itemToApiObject(item: Service): ServiceApiObject {
    return {...item, name: item.identity, publicDid: item.did, enabled: true, endpointsBase: item.endpoint} as any;
  }
}

const converterObject = {
  identity: 'name',
  did: 'publicDid',
};

export class ServiceApi extends ListItemApi<Service, ServiceApiObject> {
  constructor() {
    super(new ServiceConverter());
  }

  async statistic(did: string, year: number, month: number): Promise<StatisticsMonthly> {
    const refresh = year === new Date().getFullYear() && month === (new Date().getMonth() + 1);
    const res = await httpGetJSON(
      `${appConfig().ZAKA_BACKEND}/service-statistics/monthly?year=${year}&monthOfYear=${month}&serviceDid=${did}&refresh=${refresh}`,
      signUpToken()
    );
    return res;
  }

  protected async fetchOne(condition?: GetCondition): Promise<ServiceApiObject> {
    const name = condition && condition.identity ? condition.identity : '';
    return await httpGetJSON(`${customerPrefix()}/service/${name}`, signUpToken());
  }

  protected async fetchList(condition?: GetCondition) {
    // return {itemsTotal: 0, items: []};
    const resp = await httpGetJSON(`${customerPrefix()}/services${conditionToQueryString(condition, converterObject)}`);
    return resp;
  }

  protected async fetchCreate(item: ServiceApiObject): Promise<ServiceApiObject> {
    return item;
  }

  protected async fetchSave(item: ServiceApiObject): Promise<void> {
    // do nothing
  }

  protected async fetchDelete(item: ServiceApiObject): Promise<void> {
    // do nothing
  }

}
