import {EMPTY_TEMPLATE, ServiceProject, ServiceProjectEnabledStatus, ServiceProjectStatus} from "../../domain/Service";
import {httpDelete, httpGetJSON, httpPostEmpty, httpPostJSON, httpPutFile, httpPutJSON, signUpToken} from "./Fetch";
import {appConfig, customerPrefix} from "../cfg/config";
import {conditionToQueryString} from "./QueryString";
import {TEMPLATES_MAP} from "./TemplatesSet";
import {Converter} from "./Converter";
import {ChangeOptions, GetCondition, ListItemApi} from "./ListItemApi";

interface ProjectApiObject {
  name: string;
  owner: string;
  did?: string;
  description: string;
  shortDescription: string;
  logo: string;
  banner: string;
  categories: string[];
  countries: string[];
  keywords: string[];
  status: ServiceProjectStatus;
  paymentPlan?: string;
  addons?: string[];
  onPrem?: boolean;
  templateName?: string;
  enabledStatus: ServiceProjectEnabledStatus;
  isPublic: boolean;
}

class ProjectConverter extends Converter<ServiceProject, ProjectApiObject> {
  apiObjectToItem(obj: ProjectApiObject, parent?: undefined): ServiceProject {
    const item: ServiceProject = {...obj, identity: obj.name, paymentPlan: obj.paymentPlan};
    if (obj.logo) {
      item.logo = `${appConfig().ZAKA_BACKEND}${obj.logo}`;
    }
    if (obj.banner) {
      item.banner = `${appConfig().ZAKA_BACKEND}${obj.banner}`;
    }
    if (obj.templateName) {
      item.template = TEMPLATES_MAP.get(obj.templateName) || EMPTY_TEMPLATE;
    } else {
      item.template = EMPTY_TEMPLATE;
    }
    return item;
  }

  itemToApiObject(item: ServiceProject): ProjectApiObject {
    const res: ProjectApiObject = {...item, name: item.identity, paymentPlan: item.paymentPlan};
    if (item.template && item.template !== EMPTY_TEMPLATE) {
      res.templateName = item.template.name;
    }
    delete (res as any).template;
    return res;
  }
}

const converterObject = {
  identity: 'name',
};

const FAKE_PROJECTS: ProjectApiObject[] = [
  {
    "name": "ProofSpace Oauth Demo",
    "owner": "alexey.hodkov@gmail.com",
    "description": "Demo service",
    "shortDescription": "Demo service",
    "categories": [
      "ID Verification"
    ],
    "keywords": [
      "OAuth",
      "ProofSpace",
      "Demo"
    ],
    "countries": [
      "*"
    ],
    status: ServiceProjectStatus.ACTIVE,
    "paymentPlan": "step",
    "onPrem": false,
    "addons": [
      "oauth"
    ],
    enabledStatus: ServiceProjectEnabledStatus.ENABLED,
    "isPublic": true,
    "logo": "/partner-services/did/MjUavdVQGMeKxfB3Kjao2a/logo",
    "did": "MjUavdVQGMeKxfB3Kjao2a",
    "banner": "/partner-services/did/MjUavdVQGMeKxfB3Kjao2a/banner"
  },
  {
    "name": "new hodkov banner test",
    "owner": "alexey.hodkov@gmail.com",
    "description": "test",
    "shortDescription": "test",
    "categories": [],
    "keywords": [],
    "countries": [
      "*"
    ],
    status: ServiceProjectStatus.REJECTED,
    "paymentPlan": "monthly",
    "onPrem": false,
    "addons": [],
    enabledStatus: ServiceProjectEnabledStatus.ENABLED,
    "isPublic": false,
    "logo": "/partner-services/did/8119BE5Tqq1SKCNzvriUw1/logo",
    "banner": "/partner-services/did/8119BE5Tqq1SKCNzvriUw1/banner"
  },
  {name: 'First',
    description: 'dede. lorem ipsum. safasd . some long text to see it in the what do you mean ir how can i ' +
      'do something big and mstake y this ig random generator og fg word please, do dod o df, gerfer, dfg so' +
      'ligva latina tet me try some other thing if you wanna talk about it or or or mb td das ist arketing bs' +
      'stop propogate and look at me now',
    shortDescription: 'dede. lorem ipsum. safasd . some long text to see it in the what do you mean ir how can i ' +
      'do something big and mstake y this ig random generator og fg word please, do dod o df, gerfer, dfg so' +
      'ligva latina tet me try some other thing if you wanna talk about it or or or mb td das ist arketing bs' +
      'stop propogate and look at me now, lala land, bebe band, koko kund, soso fund', addons: [], onPrem: false,
    status: ServiceProjectStatus.IN_REVIEW, keywords: [], categories: [], countries: [],
    banner: '/partner-services/did/TvW9w7NWWyNn1Ba8DFgKL2/banner',
    logo: '/partner-services/did/TvW9w7NWWyNn1Ba8DFgKL2/logo',
    owner: 'test@test', enabledStatus: ServiceProjectEnabledStatus.ENABLED, isPublic: false},
  {name: 'Second', description: 'dede', shortDescription: 'dede', addons: [], onPrem: false,
    status: ServiceProjectStatus.DRAFT, keywords: [], categories: [], countries: [],
    banner: '/partner-services/did/LW64uKj1Qo3rCYatE6TLdj/banner',
    logo: '/partner-services/did/LW64uKj1Qo3rCYatE6TLdj/logo',
    owner: 'test@test', enabledStatus: ServiceProjectEnabledStatus.ENABLED, did: 'aaaaaaa', isPublic: true},
  {name: 'Super puper', description: 'dede',
    shortDescription: 'dede. lorem ipsum. safasd . some long text to see it in the what do you mean ir how can i ' +
      'do something big and mstake y this ig random generator og fg word please, do dod o df, gerfer, dfg so' +
      'ligva latina tet me try some other thing if you wanna talk about it or or or mb td das ist arketing bs' +
      'stop propogate and look at me now ger ter be rasy so happy',
    addons: [], onPrem: false,
    status: ServiceProjectStatus.ACTIVE, keywords: [], categories: [], countries: [],
    banner: '/partner-services/did/5XzAXCJfq3Myn3yYoSLS3D/banner',
    logo: '/partner-services/did/5XzAXCJfq3Myn3yYoSLS3D/logo',
    owner: 'test@test', enabledStatus: ServiceProjectEnabledStatus.DISABLED_BY_USER, did: 'SReftr334df', isPublic: false},
  {name: 'Very Good Best Super popuper', description: 'dede', shortDescription: 'dede', addons: [], onPrem: false,
    status: ServiceProjectStatus.ACTIVE, keywords: [], categories: [], countries: [],
    banner: '/partner-services/did/Xescv76Cxw2qCWsrVSGTNn/banner',
    logo: '/partner-services/did/Xescv76Cxw2qCWsrVSGTNn/logo',
    owner: 'test@test', enabledStatus: ServiceProjectEnabledStatus.DISABLED_BY_ADMIN, did: 'bbbbbbbb', isPublic: true},
];
export class ProjectApi extends ListItemApi<ServiceProject, ProjectApiObject> {
  constructor() {
    super(new ProjectConverter());
  }

  async checkName(name: string) {
    // return {ok: true};
    return httpGetJSON(`${customerPrefix()}/project/check/${encodeURI(name)}`, signUpToken());
  }

  async sendToReview(project: ServiceProject) {
    const prj = await httpPostEmpty(`${customerPrefix()}/to-review/${encodeURI(project.identity)}`, signUpToken());
    return this.converter.apiObjectToItem(prj);
  }

  async updateLogo(service: ServiceProject, file: File) {
    if (!file) {
      return;
    }
    await httpPutFile(`${customerPrefix()}/project/${encodeURI(service.identity)}/logo`, file, 'logo', signUpToken());
  }

  async updateBanner(service:ServiceProject, file: File) {
    if (!file) {
      return;
    }
    await httpPutFile(`${customerPrefix()}/project/${encodeURI(service.identity)}/banner`, file, 'banner', signUpToken());
  }

  async disable(project: ServiceProject) {
    await httpPostEmpty(`${customerPrefix()}/disable/${encodeURI(project.identity)}`, signUpToken());
  }

  async enable(project: ServiceProject) {
    await httpPostEmpty(`${customerPrefix()}/enable/${encodeURI(project.identity)}`, signUpToken());
  }

  protected async fetchOne(condition?: GetCondition): Promise<ProjectApiObject> {
    const name = condition && condition.identity ? condition.identity : '';
    return await httpGetJSON(`${customerPrefix()}/project/${encodeURI(name as string)}`, signUpToken());
  }

  protected async fetchList(condition?: GetCondition) {
    // return {itemsTotal: FAKE_PROJECTS.length, items: FAKE_PROJECTS};
    const resp = await httpGetJSON(
      `${customerPrefix()}/projects${conditionToQueryString(condition, converterObject)}`,
      signUpToken()
    );
    return resp;
  }

  protected async fetchCreate(item: ProjectApiObject): Promise<ProjectApiObject> {
    await httpPostJSON(`${customerPrefix()}/project`, item, signUpToken());
    return item;
  }

  protected async fetchSave(item: ProjectApiObject, opt?: ChangeOptions): Promise<void> {
    console.log('i call save');
    const prevName = opt ? opt.prevIdentity : item.name;
    await httpPutJSON(`${customerPrefix()}/project/${encodeURI(prevName as string)}`, item, signUpToken());
  }

  protected async fetchDelete(item: ProjectApiObject): Promise<void> {
    await httpDelete(`${customerPrefix()}/project/${encodeURI(item.name)}`, signUpToken());
  }

}
