export enum AuthPath {
  LOGIN = 'login',
  SIGN_UP = 'sign-up'
}

export enum MainPath {
  ACCESS = 'access',
  MY_SERVICES = 'my-services',
  DENIED = 'denied'
}
