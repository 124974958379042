export enum Role {
  OPERATOR = 'OPERATOR',
  MANAGER = 'MANAGER',
  CONFIGURATOR = 'CONFIG',
  ADMIN = 'ADMIN'
}

export interface ServiceAccess {
  serviceDid: string;
  logicalRole: Role;
  serviceName: string;
  endpointUrl: string;
  enabled: boolean;
}

export interface User {
  email: string;
  serviceRecords?: ServiceAccess[];
  signInRecord?: {
    enabled: boolean;
    confirmed: boolean;
    role: any;
  }
}
