import {ServiceProject} from "../../domain/Service";
import {StatisticsMonthly} from "../../domain/Statistics";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {serviceApi} from "../api/API";

interface StatisticsState {
  isLoading?: boolean;
  // selectedService?: ServiceProject;
  statistics?: StatisticsMonthly;
}

const initialState: StatisticsState = {};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    setStatistics: (state, action: PayloadAction<StatisticsMonthly>) => {
      state.statistics = action.payload;
      state.isLoading = false;
    },
  }
});

const {setLoading, setStatistics} = statisticsSlice.actions;

export const getStatistics = createAsyncThunk(
  'get-statistics',
  async (arg: {did: string, year: number, month: number}, {dispatch}) => {
    const {did, year, month} = arg;
    dispatch(setLoading());
    const ms = await serviceApi.statistic(did, year, month);
    dispatch(setStatistics(ms));
  }
);
