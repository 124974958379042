import {find} from "lodash";
import {appConfig} from "../app/cfg/config";

export interface Container {
  baseUrl: string;
  publicKey: string;
  frontendBaseUrl?: string;
}

class ContainersCatalogue {
  private allContainers: Container[] = [];

  setContainers(list: Container[]) {
    this.allContainers = list.concat();
  }

  getContainerFrontUrlByServiceUrl(url: string) {
    const container = find(this.allContainers, (c) => url.indexOf(c.baseUrl) === 0);
    return container?.frontendBaseUrl || appConfig().SERVICE_URL;
  }

}

export const containersCatalogue = new ContainersCatalogue();
