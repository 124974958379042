import {AuthApi} from "./AuthApi";
import {ServiceApi} from "./ServiceApi";
import {ProjectApi} from "./ProjectApi";
import {CountriesApi} from "./CountriesApi";
import {CategoriesApi} from "./CategoriesApi";
import {PaymentPlanApi} from "./PaymentPlanApi";
import {ContainersApi} from "./ContainersApi";

export const authApi = new AuthApi();
export const serviceApi = new ServiceApi();
export const projectApi = new ProjectApi();
export const countriesApi = new CountriesApi();
export const categoriesApi = new CategoriesApi();
export const paymentPlansApi = new PaymentPlanApi();
export const containersApi = new ContainersApi();
