import {httpGetJSON, httpPostJSON, token} from './Fetch';
import {appConfig, networkPrefix, qrAuthUrl, userInfoUrl} from '../cfg/config';
import {wait} from '../../utils/TimeUtils';
import {Role, User} from "../../domain/User";
import {rndStr} from "../../utils/Random";

const FAKE_SERVICE_RECORDS = [
  {
    serviceDid: 'Y4wHw22eDw4ZLx53AcW3gg',
    serviceName: 'TestSignUp15-2',
    logicalRole: Role.ADMIN,
    enabled: true,
    endpointUrl: ''
  }, {
    serviceDid: 'VVe5bTMeM5mMyv7A5ESosC',
    serviceName: 'Буде весна!',
    logicalRole: Role.OPERATOR,
    enabled: true,
    endpointUrl: ''
  }, {
    serviceDid: '7GXAxMR9dCEWa7UmHjcpMj',
    serviceName: 'Томат Чернозем',
    logicalRole: Role.OPERATOR,
    enabled: true,
    endpointUrl: ''
  }, {
    serviceDid: 'NTHCi1VWNDcFYtzPn9FKqt',
    serviceName: 'laboratorium \'Be Healthy\'',
    logicalRole: Role.ADMIN,
    enabled: true,
    endpointUrl: ''
  }, {
    serviceDid: '4RC911DV7eSGkSxfWQKWkH',
    serviceName: 'kyc-test',
    logicalRole: Role.MANAGER,
    enabled: true,
    endpointUrl: ''
  }, {
    serviceDid: '2RjEBo9zXkJByXm14xQHtg',
    serviceName: 'hodkov-test',
    logicalRole: Role.CONFIGURATOR,
    enabled: true,
    endpointUrl: ''
  }, {
    serviceDid: 'MjUavdVQGMeKxfB3Kjao2a',
    serviceName: 'ProofSpace Oauth Demo',
    logicalRole: Role.MANAGER,
    enabled: true,
    endpointUrl: ''
  }
];

export class AuthApi {

  async getStatus(): Promise<User|undefined> {
    /*await wait(3000);
    return {
      email: 'test@test.test',
      serviceRecords:
    };*/
    if (!token()) {
      return undefined;
    }

    let resp: User | undefined = undefined;
    try {
      resp = await httpGetJSON(userInfoUrl(), token(), {ignoreErrors: true});
      console.log('we have user', resp);
      // if (resp) resp.serviceRecords = FAKE_SERVICE_RECORDS;
    } catch (e) {
      console.log('error', e);
      // do nothing
    }
    return resp;
  }

  async getQrAuthData(): Promise<{deviceCode: string, qrUrl: string, nonce: string, when: number}> {
    const when = Date.now();
    const nonce = rndStr(16);
    const myUrl = appConfig().BACKEND; //'https://test.proofspace.id/login-server'; // window.location.href;
    return await httpPostJSON(qrAuthUrl(), {when, nonce, myUrl});
  }

  async createSignInRecord(email: string, password: string): Promise<{
    email: string,
    confirmed: boolean,
    enabled: boolean,
    role: any,
    password: string,
    signUpToken: string
  }> {
    return await httpPostJSON(`${networkPrefix()}/newSignup`, {email, password}, token());
  }

  async getSignUpToken(): Promise<{email: string, token: string}> {
    return await httpGetJSON(`${networkPrefix()}/signupToken`, token());
  }

  async createAuthCode(applicationDomain: string): Promise<{
    code: string,
    when: number,
    nonce: string
  }> {
    const applicationId = 'dashboard';
    const when = Date.now();
    const nonce = rndStr(16);

    return await httpPostJSON(
      `${networkPrefix()}/transfer-code`,
      {applicationId, applicationDomain, when, nonce},
      token()
    );
  }

}
