import {User} from "../../domain/User";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {authApi, serviceApi} from "../api/API";
import {dropSignUpToken, dropToken, httpPostEmpty, setSignUpToken, setToken, token} from "../api/Fetch";
import {sseService} from "../api/SSEService";
import {rndStr} from "../../utils/Random";
import {cloneDeep} from "lodash";
import {appConfig, customerPrefix} from "../cfg/config";
import {containersCatalogue} from "../../domain/Container";

export interface AuthState {
  isLoading?: boolean;
  isQrAuthLoading?: boolean;
  user?: User;
  qrUrl?: string;
  when?: number;
  error?: string;
}

const initialState: AuthState = {
  user: undefined,
  isLoading: true, // TODO: hack. Investigate what and were Vova add bug.
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    setQrAuthLoading: (state) => {
      state.isQrAuthLoading = true;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    setQrData: (state, action: PayloadAction<{qrUrl: string, when: number}>) => {
      state.qrUrl = action.payload.qrUrl;
      state.when = action.payload.when;
      state.isQrAuthLoading = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    dropUser: (state) => {
      state.user = undefined;
      state.isLoading = false;
    },
  }
});

const {setLoading, setQrAuthLoading, setUser, setQrData, setError, dropUser} = authSlice.actions;

export const status = createAsyncThunk('status', async (_, {dispatch}) => {
  dispatch(setLoading());
  const user = await authApi.getStatus();
  console.log('user is', user);
  if (user) {
    if (user.signInRecord) {
      const tkn = await authApi.getSignUpToken();
      console.log('sign up token is', tkn);
      setSignUpToken(tkn.token);
    }
    dispatch(setUser(user));
  } else {
    dispatch(logout());
  }
});

export const getQrAuth = createAsyncThunk('get-qr-auth', async(_, {dispatch}) => {
  dispatch(setQrAuthLoading());
  const r = await authApi.getQrAuthData();
  console.log('i have response', r);
  sseService.init(
    r.deviceCode,
    (token) => dispatch(updateToken(token)),
    (msg) => dispatch(setError(msg))
  );
  dispatch(setQrData({qrUrl: r.qrUrl, when: r.when}));
});

export const logout = createAsyncThunk('logout', async (_, {dispatch}) => {
  dropToken();
  dropSignUpToken();
  try {
    await httpPostEmpty(`${customerPrefix()}/logout`);
  } catch (e) {
    console.log('Unable to log out', e);
  }
  dispatch(dropUser());
});

export const updateToken = createAsyncThunk('update-token', async (token: string, {dispatch}) => {
  console.log('what is inside token?', token);
  setToken(token);
  await dispatch(status());
});

export const createSignUpRecord = createAsyncThunk(
  'create-sign-up-record',
  async (arg: {user: User, password: string}, {dispatch}) => {
    const {user, password} = arg;
    const r = await authApi.createSignInRecord(user.email, password);
    setSignUpToken(r.signUpToken);
    const u = cloneDeep(user);
    u.signInRecord = {
      enabled: true,
      confirmed: true,
      role: 'CUSTOMER'
    }
    dispatch(setUser(u));
  }
);

export const generateServiceAuthUrl = async (endPointPrefix: string, serviceDid: string) => {
  const url = containersCatalogue.getContainerFrontUrlByServiceUrl(endPointPrefix);
  const r = await authApi.createAuthCode(new URL(url).hostname);
  return `${url}/${serviceDid}?code=${r.code}`;
};

export const createServiceAuthLink = async (endPointPrefix: string, serviceDid: string, cb: (url: string) => void) => {
  const url = await generateServiceAuthUrl(endPointPrefix, serviceDid);
  cb(url);
};

export const createAuthLinkByServiceName = async (serviceName: string, cb: (url: string) => void) => {
  const service = await serviceApi.one({identity: serviceName});
  const url = await generateServiceAuthUrl(service.endpoint, service.did);
  cb(url);
};
